document.addEventListener('DOMContentLoaded', () => {
    
    // GESTIONE DEL MENÙ MOBILE
	const currentWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
	if (currentWidth <= 768) {
		document.getElementsByClassName('menu-item--has-sub-items menu-item--level-0').forEach(elem => {
			elem.addEventListener('click', function (event) {
				if (this.__x.$data.open2 == true) {
					this.__x.$data.open2 = false;
				}

				if (this.__x.$data.open2 == true) {
					event.preventDefault();
				}

				this.__x.$data.open2 = !this.__x.$data.open2;
			}, false);
		});
	}

    // GESTIONE OVERLAY
    document.querySelectorAll('.adam__navbar__language__change.change-desktop, .menu-toggler-mobile, .icon-close').forEach(el => {
        el.addEventListener('click', () => {
            const classList = ['adam__navbar--open', 'adam__navbar--open-sidebar'], navClassList = document.querySelector('nav').classList;
            const some = classList.some((el) => navClassList.contains(el)), every = classList.every((el) => navClassList.contains(el));
            document.body.classList.toggle('noScroll', some === every);
            document.querySelector('.dark-overlay').classList.toggle('active', some === every);
        })
    });
    
    // GESTIONE DEL LOOP DEI VIDEO - LOOP PARTE QUANDO ENTRA IN VISTA E SI FERMA QUANDO NON LO VEDI PIÙ
    // QUANDO RIENTRA IN VISTA RICOMINCIA
    document.querySelectorAll('video').forEach(function (el) {
        const id = el.id, video = document.getElementById(id);
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            try {
                const ratio = entry.intersectionRatio;
                if (ratio > 0) { video.play(); }
                else {
                    video.currentTime = 0;
                    video.load();
                }
            }
            catch (e) { }
        }, { threshold: [0, 1] });
        observer.observe(el);
    });
});